<template>
  <div>
    <PageTitle page-title="Contact Us" />

    <!--Contact-us-->
    <section class="contact_us section-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3>Contact Info</h3>
          </div>
          <div class="col-md-6">
            <div class="contact_detail">
              <ul>
                <li>
                  <div class="icon_wrap">
                    <i class="fa fa-map-marker" aria-hidden="true"></i>
                  </div>
                  <div class="contact_info_m">Leicester, LE5</div>
                </li>
                <li>
                  <div class="icon_wrap">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                  </div>
                  <div class="contact_info_m">
                    <a href="tel:+447443801790">07443 801 790</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <div class="contact_detail">
              <ul>
                <li>
                  <div class="icon_wrap">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                  </div>
                  <div class="contact_info_m">
                    <a href="mailto:sales@midlandautosales.co.uk"
                      >sales@midlandautosales.co.uk</a
                    >
                  </div>
                </li>
                <li>
                  <div class="icon_wrap">
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                  </div>
                  <div class="contact_info_m">
                    Monday - Saturday 10am - 6pm <br />
                    Sunday - By Appointment
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="col-md-12">
            <div class="contact_detail">
              <div class="map_wrap">
                <iframe
                  src="https://maps.google.com/maps?q=11%20wyvern%20avenue,%20leicester&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  width="100%"
                  frameborder="0"
                  style="border:0"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>
    <!-- /Contact-us-->
  </div>
</template>

<script>
  export default {
    name: 'Contact',
    components: {
      PageTitle: () => import('../components/PageTitle'),
    },
  }
</script>

<style scoped>
  .page-header {
    background-image: url('../../public/assets/images/midland-auto-sales-contact-us-banner.jpg');
  }
</style>
